// This is for sending post requests to the TSALE messages
export const postSender = async (requestData, URL) => {
    try {

        console.log("Request: " + JSON.stringify(requestData));

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)
        });

        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);

        // Check if the response is OK (status in the range 200-299)
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }

        const data = await response.json();

        return data;
    } catch (error) {
        console.log("Error posting data:", error);

        return { error: error.message };
    }
};

// This is for sending get requests to the Authorization server
export const getSender = async (URL) => {
    try {
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "fvauth": "fvauth@123" // This is an important header to send to the authorization server
            }
        });

        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);

        // Check if the response is OK (status in the range 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error("Error getting data:", error);

        return { error: error.message };
    }
};
