// **************************** Pre-filled Sale Reconciliation request ****************************
export const SaleReconciliationRequest = {
    Hdr: {
        MsgFct: "FSCQ",
        PrtcolVrsn: "1.0",
        XchgId: 9999999999999999,
        CreDtTm: "2023-11-21T16:36:16.262",
        InitgPty: {
            PtyId: "party01-model01-1234567891225962233",
        },
    },
    RcncltReq: {
        Envt: {
            Mrchnt: {
                MrchntId: "FNVM00000000001",
            },
        },
        Tx: {
            RcncltTp: "AcquirerSynchronisaton",
            SaleTxId: {
                // Needs to be updated on request
                TxDt: "2023-11-21T16:36:16.262",
                TxRef: "1234",
            },
            SaleRcncltId: "0725-TEE",
        },
    }
};
