// **************************** Pre-filled Sale Payment Intent request ****************************
export const SalePaymentIntentRequest = {
	Hdr: {
		CreDtTm: "2023-12-21T12:58:34.264266", //Updated when sent
		PrtcolVrsn: "1.0",
		XchgId: 9999999999999999,
		InitgPty: {
			PtyId: "party02-Model-RAUMIL7891225962233",
		},
		MsgFct: "FSPQ",
	},
	PmtIntntReq: {
		Tx: {
			SaleRefId: "intent",
			SaleTxId: {
				TxDt: "2023-12-21T12:58:34.297273", //Updated when sent
				TxRef: "052",
			},
			CstmrOrdr: {
				CcyCd: "CAD",
				FrcstdAmt: "11.30",
				DtldAmt: {
					AmtGoodsAndSvcs: "10.00",
					Tax: [
						{
							TaxTp: "HMST",
							TaxAmt: "1.30",
						}
					]
				}
			}
		},
		Envt: {
			POI: {
				POIId: "FNV00001",
			},
			Mrchnt: {
				MrchntId: "FNVM00000000001",
			},
		}
	}
};
