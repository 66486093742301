// ********************** Connection Details for the Authorization server **********************


// export const AuthServer_URL = "http://localhost:8110";
export const AuthServer_URL = "https://auth-demo.fvpay.ca/api";

export function getFetchSecretURL(termID) {
	return AuthServer_URL + "/onboard/" + termID;
}

export function getTOTPURL(termID, totp) {
	return AuthServer_URL + "/onboardId/" + termID + "/totp/" + totp;
}


