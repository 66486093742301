import React, { useState } from "react";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SalePaymentRequest } from "../json/SalePaymentRequest.js";
import { Ottawa_SalePayment_URL } from "../js/OttawaServerInfo.js";
import { London_SalePayment_URL } from "../js/LondonServerInfo.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Form } from "react-bootstrap";

let paymentRequest = SalePaymentRequest;

const SalePaymentForm = () => {
	// Holds the name of the party sending the request
	const initPartyID = localStorage.getItem("partyID");
	// Holds Total Amount
	const initTtlAmt = SalePaymentRequest.PmtReq.Tx.TxDtls.TtlAmt;
	// Holds POI ID this is different from the on boarding ID. Copy over the ID Displayed on the terminal Setup page
	const initPOIID = SalePaymentRequest.PmtReq.Envt.POI.POIId;
	const initSaleReferenceID = SalePaymentRequest.PmtReq.Tx.SaleRefId;
	// Holds the ID for the transaction this needs to be unique for each transaction per terminal per batch
	// So for termainal FNV00005 and for Batch (or Reconcilation ID) 0725-TEE the transaction must be unique in it. ususally start at one and increment
	const initSaleTransactionIdentificationReference = SalePaymentRequest.PmtReq.Tx.SaleTxId.TxRef;
	// This is for the Batch the payment belongs to (MAX 8 characters)
	const initSaleReconcilationID = SalePaymentRequest.PmtReq.Tx.SaleRcncltId;
	const initTransactionType = SalePaymentRequest.PmtReq.Tx.TxTp;
	const initServiceAttribute = SalePaymentRequest.PmtReq.Tx.SvcAtr;

	const [partyID, setPartyID] = useState(initPartyID);
	const [ttlAmt, setTtlAmt] = useState(initTtlAmt);
	const [POIID, setPOIID] = useState(initPOIID);
	const [displayText, setDisplayText] = useState("Initial Text");
	const [saleReferenceID, setSaleReferenceID] = useState(initSaleReferenceID);
	const [saleTransactionIdentificationReference, setSaleTransactionIdentificationReference] = useState(initSaleTransactionIdentificationReference);
	const [saleReconcilationID, setSaleReconcilationID] = useState(initSaleReconcilationID);
	// used to switch between london and ottawa server
	const [isLondon, setIsLondon] = useState(true);
	const [transactionType, setTransactionType] = useState(initTransactionType);
	const [transactionTypeDisplay, setTransactionTypeDisplay] = useState("Card Payment");
	const [serviceAttribute, setServiceAttribute] = useState(initServiceAttribute);
	const [serviceAttributeDisplay, setServiceAttributeDisplay] = useState("Initial Reservation");

	const handleRadioChange = (event) => {
		setIsLondon(event.target.value === "true");
	};

	const handleFetchData = async () => {
		if (!validateFields()) {
			return;
		}
		console.log("Form Submitted");
		paymentRequest.Hdr.InitgPty.PtyId = partyID;
		paymentRequest.PmtReq.Tx.TxDtls.TtlAmt = ttlAmt;
		paymentRequest.PmtReq.Envt.POI.POIId = POIID;
		paymentRequest.PmtReq.Tx.SaleRefId = saleReferenceID;
		paymentRequest.PmtReq.Tx.SaleTxId.TxRef = saleTransactionIdentificationReference;
		paymentRequest.PmtReq.Tx.SaleRcncltId = saleReconcilationID;
		paymentRequest.PmtReq.Tx.TxTp = transactionType;

		if (transactionType === "RESA"){
			paymentRequest.PmtReq.Tx.SvcAtr = serviceAttribute;
		} else {
			delete paymentRequest.PmtReq.Tx.SvcAtr;
		}

		// ****************************
		// Important to set the date before sending
		paymentRequest.Hdr.CreDtTm = new Date().toISOString();
		paymentRequest.PmtReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		// ****************************

		let data = "";
		try {
			if (isLondon) {
				console.log("Sent to london: " + London_SalePayment_URL);
				data = await postSender(paymentRequest, London_SalePayment_URL);
			} else {
				console.log("Sent to Ottawa: " + Ottawa_SalePayment_URL);
				data = await postSender(paymentRequest, Ottawa_SalePayment_URL);
			}
			if (data.error != null) {
				setDisplayText(data.error);
			} else {
				setDisplayText(data);
			}
		} catch (error) {
			setDisplayText(error);
		}
	};

	const resetValues = () => {
		console.log(new Date());
		// This is the default PartyID. you can have whatever party ID youd like
		setPartyID("party02-Model-RAUMIL7891225962233");
		setTtlAmt(initTtlAmt);
		setPOIID(initPOIID);
	};

	const updatePartyID = (ID) => {
		localStorage.setItem("partyID", ID);
		setPartyID(ID);
	};

	const amountReEx = new RegExp("^([0-9]*[.]{0,1}[0-9]{0,2})$");

	const validateFields = () => {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		const forms = document.querySelectorAll(".form-control");
		let success = true;
		// Loop over them and prevent submission
		Array.from(forms).forEach((form) => {
			switch (form.id) {
				case "saleReferenceID":
				case "saleTransactionIdentificationReference":
				case "ptyID":
					if (form.value.length < 1 || form.value.length > 35) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "ttlAmt":
					if (!amountReEx.test(form.value)) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "POIID":
					if (form.value.length < 1 || form.value.length > 8) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "saleReconcilationID":
					if (form.value.length < 1 || form.value.length > 10) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				default:
					success = false;
			}
		});
		return success;
	};

	const updateTransactionType = (txTp) => {
		switch (txTp) {
			case "Card Payment":
				setTransactionType("CRDP");
				break;
			case "Refund":
				setTransactionType("RFND");
				break;
			case "Reservation":
				setTransactionType("RESA");
				break;
			default:
				break;
		}

		setTransactionTypeDisplay(txTp);
	};

	const updateServiceAttribute = (serviceAttribute) => {
		switch (serviceAttribute) {
			case "Inital Reservaton":
				setServiceAttribute("IRES");
				break;
			case "Payment Reservaton":
				setServiceAttribute("PRES");
				break;
			case "First Recurring":
				setServiceAttribute("FREC");
				break;
			case "Following Recurring":
				setServiceAttribute("RREC");
				break;
			default:
				break;
		}

		setServiceAttributeDisplay(serviceAttribute);
	};

	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Sale Payment Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="ptyID">Transaction Type</label>
							<div className="dropdown">
								<button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{transactionTypeDisplay}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateTransactionType("Card Payment")}>
											Card Payment
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateTransactionType("Refund")}>
											Refund
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateTransactionType("Reservation")}>
											Reservation
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<label htmlFor="ptyID">Party ID</label>
							<input type="text" id="ptyID" className="form-control mb-2" value={partyID} onChange={(e) => updatePartyID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="ttlAmt">Total Amount</label>
							<input type="text" id="ttlAmt" className="form-control mb-2" value={ttlAmt} onChange={(e) => setTtlAmt(e.target.value)} />
							<div className="invalid-feedback">Must be in format of 0.00</div>
						</div>
						<div>
							<label htmlFor="POIID">POI ID</label>
							<input type="text" id="POIID" className="form-control mb-2" value={POIID} onChange={(e) => setPOIID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 8 characters</div>
						</div>
						{(transactionType === "RESA") ? (
							<div>
								<label>Service Attribute</label>
								<div className="dropdown">
									<button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										{serviceAttributeDisplay}
									</button>
									<ul className="dropdown-menu">
										<li>
											<button className="dropdown-item" type="button" onClick={() => updateServiceAttribute("Initial Reservation")}>
												Initial Reservation
											</button>
										</li>
										<li>
											<button className="dropdown-item" type="button" onClick={() => updateServiceAttribute("Payment Reservation")}>
												Payment Reservation
											</button>
										</li>
										<li>
											<button className="dropdown-item" type="button" onClick={() => updateServiceAttribute("First Recurring")}>
												First Recurring
											</button>
										</li>
										<li>
											<button className="dropdown-item" type="button" onClick={() => updateServiceAttribute("Following Recurring")}>
												Following Recurring
											</button>
										</li>
									</ul>
								</div>
							</div>
						) : (<></>)}
						<div>
							<label htmlFor="saleReferenceID">Sale Reference ID</label>
							<input type="text" id="saleReferenceID" className="form-control mb-2" value={saleReferenceID} onChange={(e) => setSaleReferenceID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="saleTransactionIdentificationReference">Sale Transaction Identification Reference (This must be different for each transaction in the Batch or per Sale Reconciliation ID)</label>
							<input type="text" id="saleTransactionIdentificationReference" className="form-control mb-2" value={saleTransactionIdentificationReference} onChange={(e) => setSaleTransactionIdentificationReference(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="saleReconcilationID">Sale Reconcilation ID</label>
							<input type="text" id="saleReconcilationID" className="form-control mb-2" value={saleReconcilationID} onChange={(e) => setSaleReconcilationID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 10 characters</div>
						</div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
						<Form.Group>
							<Form.Check type="radio" label="London Server" name="booleanRadios" value="true" checked={isLondon === true} onChange={handleRadioChange} />
							<Form.Check type="radio" label="Ottawa Server" name="booleanRadios" value="false" checked={isLondon === false} onChange={handleRadioChange} />
						</Form.Group>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {displayText}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SalePaymentForm;
