// **************************** Pre-filled Sale Payment request ****************************
export const SalePaymentRequest = {
	Hdr: {
		CreDtTm: "2023-12-21T12:58:34.264266", //Updated when sent
		PrtcolVrsn: "1.0",
		XchgId: 9999999999999999,
		InitgPty: {
			PtyId: "party02-Model-RAUMIL7891225962233",
		},
		MsgFct: "FSPQ",
	},
	PmtReq: {
		Tx: {
			TxCaptr: "1",
			MrchntCtgyCd: "6010",
			TxTp: "CRDP",
			SvcAtr: "IRES",
			SaleRefId: "sale",
			SaleTxId: {
				TxDt: "2023-12-21T12:58:34.297273", //Updated when sent
				TxRef: "052",
			},
			TxDtls: {
				CcyCd: "CAD",
				TtlAmt: "11.30",
				DtldAmt: {
					AmtGoodsAndSvcs: "10.00",
					Tax: [
						{
							TaxTp: "HMST",
							TaxAmt: "1.30",
						},
					],
				},
			},
			SaleRcncltId: "0725-TEE",
		},
		Envt: {
			POI: {
				POIId: "FNV00001",
			},
			Mrchnt: {
				MrchntId: "FNVM00000000001",
			},
		},
		Cntxt: {
			SaleCntxt: {
				CshrId: "4",
				CshrLang: "en",
			},
		},
	},
};
