import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import Layout from "./pages/Layout.js";

function App() {
    return <Layout />;
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);