import React, { useRef, useState } from "react";
import "../css/App.css";
import QRCode from "react-qr-code";
import { getFetchSecretURL, getTOTPURL } from "../js/AuthServerInfo";
import { getSender } from "../json/RequestSender";
import "bootstrap/dist/css/bootstrap.min.css";

const FVAuthForm = () => {
	// Used to know when to show the QR Code
	const [connectionStatus, setStatus] = useState("Not Connected");
	// Onboarding ID this will have the spaces removed before sending it
	const [onBoardingID, setOnBoardingID] = useState("PAX CA A920 0823332053");
	// 6 digit Time Based One Time Password (This will be shown on the terminal after you scan the QR Code)
	const [TOTP, setTOTP] = useState("");
	// URL which is represented as a QR Code
	const [url, setUrl] = useState("");
	// Used to know when the fetch has started
	const [fetchInitiated, setFetchInitiated] = useState(false);
	const [fetchSuccess, setFetchSuccess] = useState("false");
	const [fetchResponse, setFetchResponse] = useState("");
	const [qrIsVisible, setQrIsVisible] = useState(false);
	const qrCodeRef = useRef(null);
	const [POIID, setPOIID] = useState("PAX CA A920 0823332053");

	const fetchSecret = async () => {
		setFetchInitiated(true);
		let data = "";
		try {
			let tID = "";
			// strips spaces from onboarding ID
			for (let index = 0; index < onBoardingID.length; index++) {
				const element = onBoardingID[index];
				if (element !== " ") {
					tID += element;
				}
			}

			data = await getSender(getFetchSecretURL(tID));
			console.log("Response:", data);

			if (data.error !== "") {
				console.error("Error fetching:", data.error);
				setFetchResponse(data.error);
				setFetchSuccess("Failed");
				return;
			}

			console.log(data.secret);
			//This needs to be in this format to ensure the user entered the right ID and the terminal will reject the qrcode otherwise
			setUrl("otpauth://totp/FVAuth?onBoardId=" + data.onboardId + "&secret=" + data.secret + "&POIId=" + data.POIId);
			setPOIID(data.POIId);
			setQrIsVisible(true);
			setFetchResponse("secret: " + data.secret);
			setFetchSuccess("Success");
		} catch (error) {
			console.error("Error fetching secret:", error);
			setFetchResponse(data);
			setFetchSuccess("Failed");
		}
	};

	const SubmitOTP = async () => {
		try {
			let tID = "";
			// strips spaces from onboarding ID
			for (let index = 0; index < onBoardingID.length; index++) {
				const element = onBoardingID[index];
				if (element !== " ") {
					tID += element;
				}
			}
			let data = await getSender(getTOTPURL(tID, TOTP));

			console.log("Response:", data);

			if (data.error !== "") {
				console.error("Error fetching:", data.error);
				return;
			}

			if (data.result === "true") {
				console.log("Success");
				setStatus("Success!!!");
			} else {
				setStatus("Not Connected");
			}
		} catch (error) {
			console.error("Error fetching secret:", error);
		}
	};

	return (
		<div className="container">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Test Setting up a New Terminal</h1>
				</div>
				<div className="inputWrapper">
					<div className="scrollView">
						<h3>Enter the ID displayed on your terminal</h3>
						<div className="container mt-4">
							<div className="input-group mb-3">
								<div className="input-group-append">
									<button className="btn btn-primary" onClick={fetchSecret}>
										Fetch QR code
									</button>
								</div>
								<input type="text" className="form-control" placeholder="Terminal ID" value={onBoardingID} onChange={(e) => setOnBoardingID(e.target.value)} />
							</div>
						</div>

						{fetchInitiated && (
							<>
								<h3>
									Status: {fetchSuccess} <br />
									Response: {fetchResponse} <br />
									POI ID: {POIID}
								</h3>
								<p>The POI ID is important note it down for sending a Sale Payment Request</p>
							</>
						)}

						<div className="qrcode__container">
							<div className="qrcode__container--parent" ref={qrCodeRef}>
								{qrIsVisible && (
									<div className="qrcode__download">
										<div className="qrcode__image">
											<QRCode value={url} size={300} />
										</div>
									</div>
								)}
							</div>
						</div>

						<h3>Scan this using your A920 then enter the TOTP from your device into the field below</h3>
						<div className="container mt-4">
							<div className="input-group mb-3">
								<div className="input-group-append">
									<button className="btn btn-primary" onClick={SubmitOTP}>
										Submit OTP
									</button>
								</div>
								<input type="text" className="form-control" placeholder="Enter TOTP (Refreshes every 30s)" value={TOTP} onChange={(e) => setTOTP(e.target.value)} />
							</div>
						</div>
						<h3>Connection Status: {connectionStatus}</h3>
					</div>
				</div>
			</div>

			<div className="footer"></div>
		</div>
	);
};

export default FVAuthForm;
