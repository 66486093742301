import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import FVAuthForm from "./FVAuth";
import SalePaymentForm from "./SalePayment";
import SaleReconciliationForm from "./SaleReconciliation";
import SalePaymentIntentForm from "./SalePaymentIntent";

const Layout = () => {
	const [activePage, setActivePage] = useState("FVAuth");

	useEffect(() => {
		// Check localStorage for the last active page
		const lastPage = localStorage.getItem("lastPage");
		if (lastPage) {
			setActivePage(lastPage);
		}
	}, []);

	useEffect(() => {
		// Update localStorage whenever activePage changes
		localStorage.setItem("lastPage", activePage);
	}, [activePage]);

	const renderPage = () => {
		switch (activePage) {
			case "SalePayment":
				return <SalePaymentForm />;
			case "Reconciliation":
				return <SaleReconciliationForm />;
			case "Intent":
				return <SalePaymentIntentForm />;
			default:
				return <FVAuthForm />;
		}
	};

	return (
		<>
			<Navbar expand="lg" className="bg-body-tertiary">
				<Container>
					<Navbar.Brand href="#home">React FV TSALE</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link onClick={() => setActivePage("FVAuth")}>Test On-Boarding</Nav.Link>
							<Nav.Link onClick={() => setActivePage("SalePayment")}>Sale Payment</Nav.Link>
							<Nav.Link onClick={() => setActivePage("Reconciliation")}>Reconciliation</Nav.Link>
							<Nav.Link onClick={() => setActivePage("Intent")}>Intent</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<Container>{renderPage()}</Container>
		</>
	);
};

export default Layout;
